<template>
  <div class="mt-1 mx-3">
    <div>
      <b-tabs content-class="pt-1" lazy v-model="tabIndex">
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="onTabClick(tab, index)"
          :title="tab.text"
          :active="active(index)"
          title-item-class="lisaweb"
        >
          <keep-alive>
            <component :is="selected"></component>
          </keep-alive>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Companies from "@/components/profile/companies-products/insurers/index.vue";
import InsurerRisks from "@/components/profile/companies-products/insurance-risks/index.vue";
import Breakdowns from "@/components/profile/companies-products/breakdowns/index.vue";
import MandateCodes from "@/components/profile/companies-products/mandate-codes/index.vue";

import ShortcutsAndBreadcrumbsMixin from "@/mixins/ShortcutsAndBreadcrumbsMixin";

export default {
  mixins: [ShortcutsAndBreadcrumbsMixin],
  data() {
    return {
      path: "module.PROF",
      tabs: [
        { name: "Companies", text: "Compagnie" },
        { name: "InsurerRisks", text: "Prodotti" },
        { name: "Breakdowns", text: "Scorpori" },
        { name: "MandateCodes", text: "Mandati" },
      ],
      tabsGroup: "Management",
      // breadcrumbs settings: bcs & maxLevel
      bcs: [
        {
          text: "Profilo",
          path: "",
          level: 0,
          disabled: true,
        },
        {
          text: "Profilo",
          path: "",
          level: 1,
        },
        {
          text: "Gestione Compagnie e Prodotti",
          path: "",
          level: 2,
        },
        {
          text: "Companies",
          path: "module.PROF",
          tab: `#${this.selected}`,
          level: 3,
        },
      ],
      maxLevel: 3, // use to identify the tabs level
      selected: "Companies",
      tabIndex: 0,
      // isLoading: false,
    };
  },
  components: {
    Companies,
    InsurerRisks,
    Breakdowns,
    MandateCodes,
  },
};
</script>
