<template>
  <div class="mt-1 mx-3">
    <div>
      <b-tabs content-class="pt-1" lazy v-model="tabIndex">
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="onTabClick(tab, index)"
          :title="tab.text"
          :active="active(index)"
          title-item-class="lisaweb"
        >
          <keep-alive>
            <component :is="selected"></component>
          </keep-alive>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import AgencyTreasuries from "@/components/profile/treasuries/agency/index.vue";
import CompanyTreasuries from "@/components/profile/treasuries/company/index.vue";
import ShortcutsAndBreadcrumbsMixin from "@/mixins/ShortcutsAndBreadcrumbsMixin";

export default {
  mixins: [ShortcutsAndBreadcrumbsMixin],
  data() {
    return {
      path: "module.PROF.CAGG",
      tabs: [
        { name: "CompanyTreasuries", text: "Forme di pagamento di Compagnia" },
        { name: "AgencyTreasuries", text: "Forme di pagamento di Agenzia" },
      ],
      tabsGroup: "Management",
      bcs: [
        {
          text: "Profilo",
          path: "",
          level: 0,
          disabled: true,
        },
        {
          text: "Profilo",
          path: "",
          level: 1,
        },
        {
          text: "Forme di pagamento",
          path: "",
          level: 2,
        },
        {
          text: "Forme di pagamentosse di Agenzia",
          path: "module.PROF.CAGG",
          tab: `#${this.selected}`,
          level: 3,
        },
      ],
      maxLevel: 3,
      selected: "CompanyTreasuries",
      tabIndex: 0,
    };
  },
  components: {
    AgencyTreasuries,
    CompanyTreasuries,
  },
};
</script>
