<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAdd"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-input
                name="title"
                type="text"
                label="Scorporo"
                v-model="filter.byAttribute.title"
                placeholder="Inserisci uno scorporo"
              />
            </div>
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-select
                name="Compagnia"
                label="Compagnia"
                :options="companies"
                v-model="filter.byInsurer.id"
                @input="onInputInsurer"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="Tipo"
                label="Tipo"
                :options="risks"
                v-model="filter.byRiskType.id"
                :taggable="false"
                :multiple="true"
                :closeOnSelect="false"
                @input="onInputRisk"
              />
              <!-- <b-button
                type="button"
                variant="light"
                size="sm"
                @click="selectAllRisks()"
                >Seleziona tutti</b-button
              >

              <b-button
                type="button"
                variant="light"
                size="sm"
                @click="deselectAllRisks()"
                >Deseleziona tutti</b-button
              > -->
              <b-button
                :pressed.sync="myToggleR"
                variant="light"
                class="mb-2"
                @click="myToggleR ? selectAllRisks() : deselectAllRisks()"
                >{{
                  myToggleR ? "Deseleziona tutti" : "Seleziona tutti"
                }}</b-button
              >
            </div>
            <div class="col-md-3">
              <base-select
                name="Ramo"
                label="Ramo"
                :options="risk_branches"
                v-model="filter.byRiskBranch.id"
                :multiple="true"
                :closeOnSelect="false"
                @input="onInputBranch"
              />

              <!-- <b-button
                type="button"
                variant="light"
                size="sm"
                @click="selectAllBranches()"
                >Seleziona tutti</b-button
              >

              <b-button
                type="button"
                variant="light"
                size="sm"
                @click="deselectAllBranches()"
                >Deseleziona tutti</b-button
              > -->
              <b-button
                :pressed.sync="myToggleB"
                variant="light"
                class="mb-2"
                @click="myToggleB ? selectAllBranches() : deselectAllBranches()"
                >{{
                  myToggleB ? "Deseleziona tutti" : "Seleziona tutti"
                }}</b-button
              >
            </div>
            <div class="col-md-3">
              <base-select
                name="Prodotto"
                label="Prodotto"
                :options="products"
                v-model="filter.byInsuranceRisk.id"
                :taggable="false"
                :multiple="true"
                :closeOnSelect="false"
              />
              <!-- <b-button
                type="button"
                variant="light"
                size="sm"
                @click="selectAllProducts()"
                >Seleziona tutti</b-button
              >

              <b-button
                type="button"
                variant="light"
                size="sm"
                @click="deselectAllProducts()"
                >Deseleziona tutti</b-button
              > -->
              <b-button
                :pressed.sync="myToggleP"
                variant="light"
                class="mb-2"
                @click="myToggleP ? selectAllProducts() : deselectAllProducts()"
                >{{
                  myToggleP ? "Deseleziona tutti" : "Seleziona tutti"
                }}</b-button
              >
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>

    <breakdowns
      @edit="onEdit"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :filterOn="{
        byRelations: ['byInsurer', 'byRiskBranch', 'byInsuranceRisk'],
      }"
      :onlyActions="['edit']"
      @destroy="onDestroy"
      :ref="tableRef"
    ></breakdowns>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import Breakdowns from "../../../tables/Breakdowns.vue";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import { mapGetters, mapActions } from "vuex";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      myToggleR: false,
      myToggleB: false,
      myToggleP: false,
      repository: "breakdown",
      resource: "breakdowns",
      filterName: "filterBreakdown",
      filter: this.initFilter(),
      items: [],
      companies: [],
      risk_branches: [],
      risks: [],
      products: [],
      tableRef: "BreakdownTableRef",
      fields: [
        {
          key: "insurer.title",
          label: this.getDictionary("insurer"),
          sortable: true,
          sortKey: "byInsurer.title",
        },
        {
          key: "insurance_risk.risk_branch.title",
          label: this.getDictionary("title", "risk_branch"),
          sortable: true,
          sortKey: "byRiskBranch.title",
        },
        {
          key: "insurance_risk.title",
          label: this.getDictionary("title", "insurance_risk"),
          sortable: true,
          sortKey: "byInsuranceRisk.title",
        },
        {
          key: "title",
          label: this.getDictionary("title", "breakdown"),
          sortable: true,
          sortKey: "title",
        },
        {
          key: "is_net.value",
          label: this.getDictionary("is_net", "breakdown"),
          sortable: false,
        },
        {
          key: "is_tax.value",
          label: this.getDictionary("is_tax", "breakdown"),
          sortable: false,
        },
      ],
    };
  },
  components: {
    Breakdowns,
    BaseInput,
    BaseSelect,
  },
  mounted() {
    this.companies = this.getInsurers();
    this.risk_branches = this.getBranches()(this.filter.byInsurer.id);
    this.risks = this.getRisks();
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          title: null,
        },
        byInsurer: {
          id: null,
        },
        byInsuranceRisk: {
          id: null,
        },
        byRiskBranch: {
          id: null,
        },
        byRiskType: {
          id: null,
        },
      };
      return init;
    },
    onInputInsurer(insurer) {
      this.filter.byInsurer.id = insurer;
      this.filter.byRiskBranch.id = null;
      this.risk_branches = this.getBranches()(insurer);
      this.filter.byInsuranceRisk.id = null;
      if (insurer) {
        this.products = this.getProducts()(
          this.filter.byInsurer.id,
          this.filter.byRiskBranch.id,
          this.filter.byRiskType.id
        );
      } else {
        this.products = [];
      }
    },
    deselectAllBranches() {
      this.filter.byRiskBranch.id = [];
      this.filter.byInsuranceRisk.id = null;
      this.products = this.getProducts()(
        this.filter.byInsurer.id,
        this.filter.byRiskBranch.id,
        this.filter.byRiskType.id
      );
    },
    deselectAllRisks() {
      this.filter.byRiskType.id = [];
      this.filter.byInsuranceRisk.id = null;
      this.products = this.getProducts()(
        this.filter.byInsurer.id,
        this.filter.byRiskBranch.id,
        this.filter.byRiskType.id
      );
    },
    deselectAllProducts() {
      this.filter.byInsuranceRisk.id = [];
    },
    selectAllBranches() {
      this.filter.byRiskBranch.id = this.risk_branches.map(
        (branch) => branch.value
      );
    },
    selectAllRisks() {
      this.filter.byRiskType.id = this.risks.map((risk) => risk.value);
    },
    selectAllProducts() {
      this.filter.byInsuranceRisk.id = this.products.map(
        (product) => product.value
      );
    },
    onInputBranch(branches) {
      if (!branches.length) {
        this.filter.byRiskBranch.id = null;
      }
      this.filter.byInsuranceRisk.id = null;
      this.products = this.getProducts()(
        this.filter.byInsurer.id,
        branches,
        this.filter.byRiskType.id
      );
    },
    onInputRisk(risks) {
      if (!risks.length) {
        this.filter.byRiskType.id = null;
      }
      this.filter.byInsuranceRisk.id = null;
      this.products = this.getProducts()(
        this.filter.byInsurer.id,
        this.filter.byRiskBranch.id,
        risks
      );
    },
    onEdit(id) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${id}` },
      });
    },
    onAdd() {
      this.$router.push({ name: `${this.resource}.create` });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Scorporo eliminato con successo`,
          });
          this.$refs[this.tableRef].fetch();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.myToggleR = false;
      this.myToggleB = false;
      this.myToggleP = false;
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    ...mapGetters("filters", { loadFilterByName: "loadByName" }),
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getBranches: "branches",
      getRisks: "risks",
      getProducts: "products",
    }),
  },
};
</script>
